import type { CalendarEventDto } from '@/api';
import { isAfter, isBefore } from 'date-fns';
import { isSameOrAfter, isSameOrBefore } from '@/utils/datetime';
import { cloneDeep } from 'lodash';
import type { CalendarEvent } from '@/types/calendar';

export const computeResourceConflicts = (
  events: CalendarEventDto[],
  eventToCheck: CalendarEventDto,
) => {
  const resourceIds =
    eventToCheck.resources?.map((resource) => resource.id) || [];
  const eventsByResource = events
    .filter((event) => event.id !== eventToCheck.id)
    .filter((event) =>
      event.resources?.find((resource) => resourceIds.includes(resource.id)),
    );

  for (const eventByResource of eventsByResource) {
    const conflictResources = eventByResource.resources!.filter((resource) =>
      resourceIds.includes(resource.id),
    );

    if (
      isAfter(eventByResource.startAt, eventToCheck.startAt) &&
      isBefore(eventByResource.startAt, eventToCheck.endAt)
    ) {
      return conflictResources;
    }

    if (
      isAfter(eventByResource.endAt, eventToCheck.startAt) &&
      isBefore(eventByResource.endAt, eventToCheck.endAt)
    ) {
      return conflictResources;
    }

    if (
      isSameOrBefore(eventByResource.startAt, eventToCheck.startAt) &&
      isSameOrAfter(eventByResource.endAt, eventToCheck.endAt)
    ) {
      return conflictResources;
    }
  }

  return [];
};

export const computePlayerConflicts = (
  events: CalendarEventDto[],
  eventToCheck: CalendarEventDto,
) => {
  const playerIds =
    eventToCheck.trainingUnits?.map((unit) => unit.playerId) || [];
  const eventsByPlayer = events
    .filter((event) => event.id !== eventToCheck.id)
    .filter((event) =>
      event.trainingUnits?.find((unit) => playerIds.includes(unit.playerId)),
    );

  for (const eventByPlayer of eventsByPlayer) {
    const conflictPlayers = eventByPlayer.trainingUnits!.filter((unit) =>
      playerIds.includes(unit.playerId),
    );

    if (
      isAfter(eventByPlayer.startAt, eventToCheck.startAt) &&
      isBefore(eventByPlayer.startAt, eventToCheck.endAt)
    ) {
      return conflictPlayers;
    }

    if (
      isAfter(eventByPlayer.endAt, eventToCheck.startAt) &&
      isBefore(eventByPlayer.endAt, eventToCheck.endAt)
    ) {
      return conflictPlayers;
    }

    if (
      isSameOrBefore(eventByPlayer.startAt, eventToCheck.startAt) &&
      isSameOrAfter(eventByPlayer.endAt, eventToCheck.endAt)
    ) {
      return conflictPlayers;
    }
  }

  return [];
};

export const computeConflicts = (
  events: CalendarEventDto[],
): CalendarEvent[] => {
  return events.map((event) => {
    const resourceConflicts = computeResourceConflicts(events, event);
    const playerConflicts = computePlayerConflicts(events, event);

    return {
      ...cloneDeep(event),
      hasResourceConflict: resourceConflicts.length > 0,
      resourceConflictIds: resourceConflicts.map((item) => item.id),
      hasPlayerConflict: playerConflicts.length > 0,
      playerConflictIds: playerConflicts.map((item) => item.playerId),
    };
  });
};
