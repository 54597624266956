<template>
  <div ref="pageRef">
    <div class="mb-4">
      <h1 class="mb-2">{{ t('layout.default.calendar') }}</h1>
      <div class="d-flex flex-wrap ga-4">
        <v-row>
          <v-col cols="12" xl="2" lg="2" md="3" sm="4">
            <Autocomplete
              v-model="filter.trainers"
              data-cy="filterTrainers"
              append-inner-icon="i-mdi:arrow-down-drop"
              :label="t('calendarEvents.trainers')"
              title-key="name"
              hide-details
              clearable
              multiple
              return-object
              :get-data="loadResources(true)"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="3" sm="4">
            <Autocomplete
              v-model="filter.resources"
              data-cy="filterResources"
              append-inner-icon="i-mdi:arrow-down-drop"
              :label="t('calendarEvents.resources')"
              title-key="name"
              hide-details
              clearable
              multiple
              :get-data="loadResources(false)"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="3" sm="4">
            <Autocomplete
              v-model="filter.compositeActivities"
              data-cy="filterCompositeActivities"
              append-inner-icon="i-mdi:arrow-down-drop"
              :label="t('calendarEvents.compositeActivities')"
              title-key="displayName"
              hide-details
              clearable
              multiple
              :get-data="loadCompositeActivities"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="3" sm="4" class="me-auto">
            <Autocomplete
              v-model="filter.players"
              data-cy="filterPlayers"
              append-inner-icon="i-mdi:arrow-down-drop"
              :label="t('calendarEvents.players')"
              title-key="displayName"
              hide-details
              clearable
              multiple
              :get-data="loadPlayers"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="3" sm="4">
            <DateField
              v-model="filter.date"
              data-cy="filterDate"
              label-key="calendarEvents.date"
              :view-mode="filterDateMode"
              hide-details
              clearable
            />
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row class="mb-2 align-center justify-sm-space-between">
      <v-col cols="12" lg="4" md="2"></v-col>

      <v-col cols="12" lg="4" md="4" class="text-center">
        <h2>
          {{ title }}
        </h2>
      </v-col>

      <v-col cols="12" lg="4" md="6" class="d-md-flex justify-md-end">
        <div class="d-flex ga-4">
          <Btn
            data-cy="calendarToday"
            variant="outlined"
            density="comfortable"
            class="flex-1-1"
            @click="handleToday"
          >
            {{ t('common.today') }}
          </Btn>

          <v-btn-group
            variant="outlined"
            density="comfortable"
            color="primary"
            divided
          >
            <Btn
              icon="i-mdi:chevron-left"
              data-cy="calendarPrev"
              @click="handlePrev"
            />
            <Btn
              icon="i-mdi:chevron-right"
              data-cy="calendarNext"
              @click="handleNext"
            />
          </v-btn-group>

          <v-menu>
            <template #activator="{ props }">
              <Btn
                data-cy="calendarViewMode"
                variant="outlined"
                density="comfortable"
                color="primary"
                :append-icon="selectedViewMode.icon"
                v-bind="props"
              >
                {{ t(selectedViewMode.titleKey) }}
              </Btn>
            </template>
            <v-list>
              <v-list-item
                v-for="viewMode in CalendarViewModes"
                :key="viewMode.type"
                :value="viewMode.type"
                :data-cy="`calendarViewMode-${viewMode.type}`"
                @click="() => (selectedViewMode = viewMode)"
              >
                <v-list-item-title>
                  {{ t(viewMode.titleKey) }}
                </v-list-item-title>

                <template #append>
                  <v-icon :icon="viewMode.icon" />
                </template>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>

    <FullCalendar
      ref="calendarRef"
      data-cy="calendar"
      :options="calendarOptions"
    >
      <template #event-content="arg">
        <v-tooltip location="bottom">
          <div>{{ getCalendarEvent(arg.event).name }}</div>
          <div
            v-if="getCalendarEvent(arg.event).compositeActivity"
            class="d-flex justify-space-between ga-1"
          >
            <span>
              {{ getCalendarEvent(arg.event).compositeActivity!.code }} —
              {{ getCalendarEvent(arg.event).compositeActivity!.name }}
            </span>
            <v-icon
              v-if="!getCalendarEvent(arg.event).compositeActivity!.active"
              icon="i-mdi:alert-circle"
              color="red"
            />
          </div>
          <div>
            {{
              formatDistance(
                getCalendarEvent(arg.event).endAt,
                getCalendarEvent(arg.event).startAt,
              )
            }}
          </div>
          <ul
            v-if="
              getCalendarEvent(arg.event).trainingUnits &&
              getCalendarEvent(arg.event).trainingUnits.length > 0
            "
            class="mt-1 ms-6"
          >
            <li
              v-for="(trainingUnit, index) in getCalendarEvent(arg.event)
                .trainingUnits"
              :key="index"
            >
              <div class="d-flex justify-space-between ga-1">
                <span>
                  {{ trainingUnit.unitIndex }}/{{ trainingUnit.totalItems }}
                  {{ trainingUnit.playerName }} {{ trainingUnit.programName }}
                </span>
                <v-icon
                  v-if="
                    getCalendarEvent(arg.event).playerConflictIds.includes(
                      trainingUnit.playerId,
                    )
                  "
                  icon="i-mdi:alert-circle"
                  color="red"
                />
              </div>
            </li>
          </ul>
          <div
            v-if="
              getCalendarEvent(arg.event).resources &&
              getCalendarEvent(arg.event).resources!.length > 0
            "
            class="mt-1"
          >
            {{ t('calendarEvents.resources') }}
            <ul class="ms-6">
              <li
                v-for="resource in getCalendarEvent(arg.event).resources"
                :key="resource.id"
              >
                <div class="d-flex justify-space-between ga-1">
                  <span>{{ resource.name }}</span>
                  <v-icon
                    v-if="
                      getCalendarEvent(arg.event).resourceConflictIds.includes(
                        resource.id,
                      )
                    "
                    icon="i-mdi:alert-circle"
                    color="red"
                  />
                </div>
              </li>
            </ul>
          </div>
          <div
            v-if="
              getCalendarEvent(arg.event).compositeActivity &&
              !getCalendarEvent(arg.event).compositeActivity!.active
            "
            class="text-red"
          >
            {{ t('calendarEvents.inactiveCompositeActivity') }}
          </div>
          <div
            v-if="getCalendarEvent(arg.event).hasResourceConflict"
            class="text-red"
          >
            {{ t('calendarEvents.resourceConflict') }}
          </div>
          <div
            v-if="getCalendarEvent(arg.event).hasPlayerConflict"
            class="text-red"
          >
            {{ t('calendarEvents.playerConflict') }}
          </div>
          <div
            v-if="
              getCalendarEvent(arg.event).compositeActivity &&
              getCalendarEvent(arg.event).trainingUnits?.length <
                getCalendarEvent(arg.event).compositeActivity!.minimalCapacity
            "
            class="text-red"
          >
            {{
              t('calendarEvents.minimalCapacityConflict', {
                actual: getCalendarEvent(arg.event).trainingUnits.length,
                expected: getCalendarEvent(arg.event).compositeActivity
                  ?.minimalCapacity,
              })
            }}
          </div>
          <div
            v-if="
              getCalendarEvent(arg.event).compositeActivity &&
              getCalendarEvent(arg.event).trainingUnits?.length >
                getCalendarEvent(arg.event).compositeActivity!.maximalCapacity
            "
            class="text-red"
          >
            {{
              t('calendarEvents.maximalCapacityConflict', {
                actual: getCalendarEvent(arg.event).trainingUnits.length,
                expected: getCalendarEvent(arg.event).compositeActivity
                  ?.maximalCapacity,
              })
            }}
          </div>

          <template #activator="{ props }">
            <div
              :data-cy="`calendarEvent-${arg.event.title}`"
              v-bind="props"
              class="h-100 w-100 overflow-hidden px-1"
            >
              <div v-if="arg.event.title" class="d-flex justify-space-between">
                <div class="text-truncate">
                  <b>{{ arg.event.title }}</b>
                </div>
                <v-icon
                  v-if="hasConflict(arg.event)"
                  icon="i-mdi:alert-circle"
                  class="text-red"
                />
              </div>
              <div
                v-if="getCalendarEvent(arg.event).compositeActivity"
                class="text-truncate"
              >
                {{ getCalendarEvent(arg.event).compositeActivity!.code }} —
                {{ getCalendarEvent(arg.event).compositeActivity!.name }}
              </div>
              <div v-if="!arg.event.allDay">
                <div
                  v-if="!arg.event.title"
                  class="d-flex justify-space-between"
                >
                  <div class="text-truncate">
                    {{ formatTime(arg.event.start) }} -
                    {{ formatTime(arg.event.end) }}
                  </div>
                  <v-icon
                    v-if="hasConflict(arg.event)"
                    icon="i-mdi:alert-circle"
                    class="text-red"
                  />
                </div>
                <div v-else class="text-truncate">
                  {{ formatTime(arg.event.start) }} -
                  {{ formatTime(arg.event.end) }}
                </div>
              </div>
            </div>
          </template>
        </v-tooltip>
      </template>
    </FullCalendar>

    <LazyCalendarEventFormDialog
      :get-dialog-ref="getDialogRef()"
      :initial-value="currentEvent"
      :events="calendarEvents"
      :disabled="!hasUpdatePermission && !hasDeletePermission"
      @submit="handleSubmitEvent"
      @delete="handleDeleteEvent"
      @close="handleCloseEvent"
    />
  </div>
</template>

<script setup lang="ts">
import FullCalendar from '@fullcalendar/vue3';
import {
  type CalendarOptions,
  type EventClickArg,
  type EventChangeArg,
  type EventDropArg,
  type DateSelectArg,
  type EventInput,
  type DatesSetArg,
  type EventSourceFuncArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import type Dialog from '@/components/Dialog.vue';
import type { VNodeRef } from 'vue';
import type { CalendarEvent, CalendarViewMode } from '@/types/calendar';
import { useDateUtils } from '@/composables/useDateUtils';
import {
  type CalendarEventDto,
  CalendarEventsService,
  CompositeActivitiesService,
  type ReplanCalendarEventDto,
} from '@/api';
import { apiKeys } from '@/constants/apiKeys';
import { ResponseStatus } from '@/constants/responseStatus';
import { SnackbarType } from '@/constants/snackbarType';
import { DatePickerViewMode } from '@/constants/datePicker';
import type { DateInput } from '@/types/global';
import { CalendarViewModes, MIN_TIME } from '@/constants/calendar';
import { useUserStore } from '@/store/user';
import type { AutocompleteItem } from '@/components/Autocomplete.vue';
import { cloneDeep, isEqual } from 'lodash';
import { lcFirst } from '@/utils/string';
import { UserPermission } from '@/constants/userPermission';
import { computeConflicts } from '@/utils/calendarEvent';
import { add, roundToNearestMinutes } from 'date-fns';
import { parseISODuration } from '@/utils/time';

const { apiRequest, loadResources, loadCompositeActivities, loadPlayers } =
  useApi();
const { t, te, locale } = useI18n();
const { formatTime, formatDistance } = useDateUtils();
const snackbar = useSnackbar();
const { loggedUser, calendarViewMode, setCalendarViewMode } = useUserStore();
const route = useRoute();
const router = useRouter();
useSeoMeta({ title: () => t('layout.default.calendar') });

const { hasPermission } = usePermissionUtils();
const hasViewPermission = computed(() =>
  hasPermission(UserPermission.CalendarEventsView),
);
const hasCreatePermission = computed(() =>
  hasPermission(UserPermission.CalendarEventsCreate),
);
const hasUpdatePermission = computed(() =>
  hasPermission(UserPermission.CalendarEventsUpdate),
);
const hasDeletePermission = computed(() =>
  hasPermission(UserPermission.CalendarEventsDelete),
);

const pageRef = ref<HTMLDivElement | null>(null);
const calendarRef = ref<InstanceType<typeof FullCalendar>>();
const currentEvent = ref<Partial<CalendarEventDto>>();
const filterDateMode = ref<DatePickerViewMode>();
const selectedViewMode = ref<CalendarViewMode>(calendarViewMode);
const title = ref('');
const calendarEvents = ref<CalendarEventDto[]>([]);

onMounted(async () => {
  if (route.query.id) {
    const calendarEvent = await loadCalendarEvent(route.query.id as string);
    if (calendarEvent) {
      filter.date = calendarEvent.startAt as DateInput;

      handleOpenEvent(calendarEvent as CalendarEventDto);
    }
  } else if (route.query.compositeActivityId) {
    const compositeActivity = await loadCompositeActivity(
      route.query.compositeActivityId as string,
    );
    if (compositeActivity) {
      const startAt = roundToNearestMinutes(new Date(), {
        roundingMethod: 'ceil',
        nearestTo: 30,
      });
      const endAt = add(
        startAt,
        parseISODuration(compositeActivity.duration as string),
      );

      handleOpenEvent({
        compositeActivity,
        name: compositeActivity.name,
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
      });
    }
  }
});

const handleOpenEvent = (calendarEvent: Partial<CalendarEventDto>) => {
  if (
    !hasViewPermission.value &&
    !hasUpdatePermission.value &&
    !hasDeletePermission.value
  ) {
    return;
  }

  if (calendarEvent.id) {
    router.replace({ path: route.path, query: { id: calendarEvent.id } });
  }

  currentEvent.value = cloneDeep(calendarEvent);

  nextTick(() => {
    openDialog();
  });
};

const handleCloseEvent = () => {
  router.replace({ path: route.path });
};

const handleEventClick = (arg: EventClickArg) => {
  handleOpenEvent(arg.event.extendedProps as CalendarEventDto);
};

const handleEventResize = async (arg: EventChangeArg) => {
  await replanEvent(
    arg.event.extendedProps.id,
    arg.event.start!,
    arg.event.end!,
  );
};

const handleEventDrop = async (arg: EventDropArg) => {
  await replanEvent(
    arg.event.extendedProps.id,
    arg.event.start!,
    arg.event.end!,
  );
};

const handleSelect = (arg: DateSelectArg) => {
  handleOpenEvent({
    startAt: arg.start.toISOString(),
    endAt: arg.end.toISOString(),
  });
};

const handleToday = () => {
  calendarRef.value?.getApi().today();
  filter.date = null;
};

const handlePrev = () => {
  calendarRef.value?.getApi().prev();
  filter.date = null;
};

const handleNext = () => {
  calendarRef.value?.getApi().next();
  filter.date = null;
};

const hasConflict = (event: EventInput): boolean => {
  const calendarEvent = event.extendedProps as CalendarEvent;

  if (!calendarEvent) {
    return false;
  }

  const hasInactiveCompositeActivity = calendarEvent.compositeActivity
    ? !calendarEvent.compositeActivity.active
    : false;
  const hasLessTrainingUnits = calendarEvent.compositeActivity
    ? calendarEvent.trainingUnits?.length <
      calendarEvent.compositeActivity.minimalCapacity
    : false;
  const hasMoreTrainingUnits = calendarEvent.compositeActivity
    ? calendarEvent.trainingUnits?.length >
      calendarEvent.compositeActivity.maximalCapacity
    : false;

  return (
    calendarEvent.hasResourceConflict ||
    calendarEvent.hasPlayerConflict ||
    hasInactiveCompositeActivity ||
    hasLessTrainingUnits ||
    hasMoreTrainingUnits
  );
};

const getCalendarEvent = (event: EventInput): CalendarEvent => {
  return event.extendedProps as CalendarEvent;
};

const loadCalendarEvent = async (id: string) => {
  const { data: calendarEvent } = await apiRequest(
    () =>
      CalendarEventsService.getCalendarEventDetail({
        id,
      }),
    apiKeys.calendarEvent(id),
  );

  return calendarEvent.value;
};

const loadCompositeActivity = async (id: string) => {
  const { data: compositeActivity } = await apiRequest(
    () =>
      CompositeActivitiesService.getCompositeActivityDetailAsync({
        id,
      }),
    apiKeys.compositeActivity(id),
  );

  return compositeActivity.value;
};

const loadCalendarEvents = async (
  info: EventSourceFuncArg,
  successCallback: (eventInputs: EventInput[]) => void,
  failureCallback: (error: Error) => void,
) => {
  const { status, data } = await apiRequest(
    () =>
      CalendarEventsService.getCalendarEvents({
        from: info.start.toISOString(),
        to: info.end.toISOString(),
        compositeActivityIds: filter.compositeActivities ?? undefined,
        playerIds: filter.players ?? undefined,
        resourceIds: filter.resources ?? undefined,
        trainerIds: filter.trainers
          ? (filter.trainers.map((item) => item.id) as string[])
          : undefined,
      }),
    apiKeys.calendarEventList(),
  );

  if (status?.value === ResponseStatus.error) {
    snackbar.add({
      type: SnackbarType.error,
      text: t('calendarEvents.errors.calendarEventsLoadingFailed'),
    });

    failureCallback(
      new Error(t('calendarEvents.errors.calendarEventsLoadingFailed')),
    );
  }

  const events = computeConflicts(data.value || []);

  calendarEvents.value = cloneDeep(events);

  successCallback(
    events.map((event) => ({
      id: event.id,
      extendedProps: cloneDeep(event),
      title: event.name,
      start: event.startAt,
      end: event.endAt,
      backgroundColor:
        event.compositeActivity?.color ||
        'rgb(var(--v-calendar-event-background))',
      borderColor:
        event.compositeActivity?.color ||
        'rgb(var(--v-calendar-event-background))',
      textColor: 'black',
      display: 'block',
    })),
  );
};

const calendarOptions = ref<CalendarOptions>({
  allDaySlot: false,
  height: 'auto',
  locales: allLocales,
  locale: locale.value,
  plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
  headerToolbar: false,
  initialView: selectedViewMode.value.view,
  nowIndicator: true,
  editable: hasUpdatePermission.value,
  selectable: hasCreatePermission.value,
  selectMirror: true,
  selectLongPressDelay: 0,
  eventClick: handleEventClick,
  eventResize: handleEventResize,
  eventDrop: handleEventDrop,
  select: handleSelect,
  events: (info, successCallback, failureCallback) =>
    loadCalendarEvents(info, successCallback, failureCallback),
  slotMinTime: MIN_TIME,
  eventClassNames: (arg: EventInput) => {
    const classes = ['cursor-pointer'];

    if (hasConflict(arg.event)) {
      classes.push('border--conflict');
    }

    return classes;
  },
  datesSet: (dateInfo: DatesSetArg) => {
    filterDateMode.value =
      dateInfo.view.type === 'dayGridMonth'
        ? DatePickerViewMode.Months
        : DatePickerViewMode.Month;

    title.value = dateInfo.view.title;

    if (selectedViewMode.value.view !== dateInfo.view.type) {
      reloadCalendarEvents();
    }
  },
});

const dialogRef = ref<InstanceType<typeof Dialog>>();
const getDialogRef: () => VNodeRef = () => (ref) => {
  dialogRef.value = ref as InstanceType<typeof Dialog>;
};
const openDialog = () => {
  dialogRef.value?.openDialog();
};
const closeDialog = () => {
  dialogRef.value?.closeDialog();
};

const handleSubmitEvent = () => {
  currentEvent.value = undefined;
  reloadCalendarEvents();
};

const handleDeleteEvent = () => {
  closeDialog();
  reloadCalendarEvents();
};

const replanEvent = async (id: string, startAt: Date, endAt: Date) => {
  const requestBody: ReplanCalendarEventDto = {
    startAt: startAt.toISOString(),
    endAt: endAt.toISOString(),
  };

  const { error, detail } = await apiRequest(() =>
    CalendarEventsService.replanCalendarEvent({
      id,
      requestBody,
    }),
  );

  if (error.value) {
    snackbar.add({
      type: SnackbarType.error,
      text: te(`error.${lcFirst(detail)}`)
        ? t(`error.${lcFirst(detail)}`)
        : t('error.somethingWentWrong'),
    });
  }

  reloadCalendarEvents();
};

const filter = reactive<{
  trainers: AutocompleteItem[] | null;
  resources: string[] | null;
  compositeActivities: string[] | null;
  players: string[] | null;
  date: DateInput | null;
}>({
  trainers: loggedUser?.resource ? [loggedUser.resource] : null,
  resources: null,
  compositeActivities: null,
  players: null,
  date: null,
});

const reloadCalendarEvents = () => {
  calendarRef.value?.getApi().refetchEvents();
};

watch(selectedViewMode, (viewMode) => {
  setCalendarViewMode(viewMode);
  calendarRef.value?.getApi().changeView(viewMode.view);
});

watch(
  () => filter.date,
  (date: DateInput) => {
    if (date) {
      calendarRef.value?.getApi().gotoDate(date);
    }
  },
);

watch(
  () => filter.trainers,
  (val, prevVal) => {
    if (!isEqual(val, prevVal)) {
      reloadCalendarEvents();
    }
  },
);

watch(
  () => filter.resources,
  (val, prevVal) => {
    if (!isEqual(val, prevVal)) {
      reloadCalendarEvents();
    }
  },
);

watch(
  () => filter.players,
  (val, prevVal) => {
    if (!isEqual(val, prevVal)) {
      reloadCalendarEvents();
    }
  },
);

watch(
  () => filter.compositeActivities,
  (val, prevVal) => {
    if (!isEqual(val, prevVal)) {
      reloadCalendarEvents();
    }
  },
);

const resizeCalendar = useDebounceFn(() => {
  calendarRef.value?.getApi().updateSize();
}, 300);

const observeWidth = () => {
  const resizeObserver = new ResizeObserver(() => {
    resizeCalendar();
  });

  if (pageRef.value) {
    resizeObserver.observe(pageRef.value);
  }
};

onMounted(() => {
  observeWidth();
});
</script>
